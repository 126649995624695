import logo from "../../../assets/images/svg/LogoCurecall.svg";
import RightEye from "../../../assets/images/svg/BlueHandEye.svg";
import LeftEye from "../../../assets/images/svg/BlueEyeHand.svg";
import CenteredLoader from "../../../components/atoms/centeredLoader/centeredLoader";

import { PatientFormService } from "../../../services";
import { formatedEyePercentageValue } from "../../../utils";
import { useEffect, useState } from "react";
import { Container } from "../../../components";

export const AmslerFormEnd = ({
  patientFormId,
}: {
  patientFormId: number | null;
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [LeftEyePercentage, setLeftEyePercentage] = useState<string>("0");
  const [RightEyePercentage, setRightEyePercentage] = useState<string>("0");

  useEffect(() => {
    const getForm = async (patientFormId: number) => {
      setIsLoading(true);
      const { data } = await PatientFormService.getFormByPatientFormId(
        String(patientFormId)
      );
      setLeftEyePercentage(
        formatedEyePercentageValue(
          data.PatientVariables?.find(
            (e: any) =>
              e.Variable.name === "amslerGridDistordedBlurredOrMissingAreasL"
          )?.patientAnswer || 0
        )
      );
      setRightEyePercentage(
        formatedEyePercentageValue(
          data.PatientVariables?.find(
            (e: any) =>
              e.Variable.name === "amslerGridDistordedBlurredOrMissingAreasR"
          )?.patientAnswer || 0
        )
      );
      setIsLoading(false);
    };

    if (patientFormId) {
      getForm(patientFormId);
    }
  }, [patientFormId]);

  if (isLoading) {
    return <CenteredLoader />;
  }

  return (
    <Container className="[&_section]:flex [&_section]:flex-col [&_section]:gap-8 [&_section]:border [&_section]:border-[#707070] [&_div]:flex [&_div]:flex-col [&_div]:gap-4">
      <h1 className="ext-center">Résultat de votre test</h1>

      <p className=" p-4 bg-cyan-300 text-center  mx-auto rounded-md">
        Questionnaire complété
      </p>

      <div className="flex items-center justify-between w-full p-2 border border-2 border-grey-400 rounded-lg [&>div]:w-1/2 [&>div]:text-center">
        <img src={RightEye} width="140" alt="right-eye-hand" />
        <div className="flex flex-col gap-2">
          <u>Œil Droit :</u>
          <p>{RightEyePercentage}</p>
        </div>
      </div>

      <div className="flex items-center justify-between w-full p-2 border border-2 border-grey-400 rounded-lg [&>div]:w-1/2 [&>div]:text-center">
        <img src={LeftEye} width="140" alt="left-eye-hand" />
        <div className="flex flex-col gap-2">
          <u>Œil Gauche :</u>
          <p>{LeftEyePercentage}</p>
        </div>
      </div>

      <img
        src={logo}
        width="89"
        height="81"
        alt="logo-curecall"
        className="mx-auto"
      />

      <p className="text-center">
        Les résultats de votre test ont été envoyés à votre médecin.
      </p>
    </Container>
  );
};
